import React, { Component } from 'react'
import {Link  } from 'react-router-dom'


export default class Intro extends Component {
  render() {
    return (
      <div className='bg-zinc-700 w-full h-full pt-8'>
        <p className='pt-8 text-4xl text-white/80'>A message to prospective employers</p>
        <p className='text-white/80 mx-16 p-8 '>andrewrimanic.com is a website I created to demonstrate my abilities as a front end web developer. It is also my photography/videography portfolio. This website is written in Javascript using React.js with styling done using Tailwind CSS. The website is hosted on AWS Amplify with support from AWS Route 53 and S3. A Django back end to fully integrate my own e-commerce store is currently in development.</p>
        <Link className=" underline text-cyan-200" to={'/'}>Continue to Home Page</Link>
      </div>
    )
  }
}
