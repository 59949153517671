import React, { Component } from 'react'

export default class FilterBar extends Component {

  constructor(props) {
    super(props)

    this.state = {
      selected: '',
    }
    this.clickHandler = this.clickHandler.bind(this)
  }

  clickHandler(value) {
    this.setState((prev) => {
      if (prev.selected === value) {
        return { selected: '' }
      } else {
        return { selected: value }
      }
    })
  }

  render() {
    {/* Filter items */ }
    return <div
      className={`opacity-90 h-auto flex fixed mx-auto inset-x-0 z-10  bg-zinc-800`} >
      <ul className='list-none justify-center items-center flex-1 text-white'>
        {Object.keys(this.props.filters).map(text =>

          <li className={`h-12 ${this.state.selected === text ? 'underline' : ''}`}
            key={text}
            onClick={() => {
              this.props.click(text)
              this.clickHandler(text)
              this.setState({
                menuOpen: false
              })
            }
            }
          >
            {/* divider */}
            <div className='h-[1px] w-full bg-gradient-to-r from-transparent via-white/70'></div>
            <div className='flex h-full mx-4 inset-0 items-center justify-center'>

              {text}
            </div>
          </li>)}
      </ul>
    </div>


  }
}
