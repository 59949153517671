import React, { Component } from 'react'
import Navbar from './Navbar';
import Home from './Home';
import Stills from './Stills';
import About from './About';
import Motion from './Motion';
import Intro from './Intro';
import LoadingBar from './LoadingBar';
import {Route, Routes,  } from 'react-router-dom'
import Footer from './Footer';

export default class Pages extends Component {
 loadTime = 4000


 constructor(props) {
   super(props)


   this.state = {
     loadComplete: false,
     hideOverlay: false
   }
 }


 // Image preload
 importAll(r) {
   let images = [];
   r.keys().map(item => { images.push(r(item)) });
   return images;
 }


 componentDidMount() {
   // Preload all image elements
  //  this.paths = this.importAll(require.context('../images', false, /^\.\/.*\.jpg$/));
  //  this.paths.forEach(function (path, id) {
  //    const img = new Image();
  //    img.src = path;
  //    window[path] = img;
  //  }, this)


   // Set how long load pre screen stays up
  //  this.loadInterval = setInterval(() => {
  //    this.setState({ loadComplete: true });
  //    clearInterval(this.loadInterval);
  //  }, this.loadTime);


  //  //
  //  this.hideInterval = setInterval(() => {
  //    this.setState({ hideOverlay: true });
  //    clearInterval(this.hideInterval);
  //  }, this.loadTime + 2000);


 }


//  componentWillUnmount() {
//    clearInterval(this.loadInterval);
//    clearInterval(this.hideInterval);
//  }


 render() {


   return (
     <div className='h-screen w-screen relative '>
       {/* { // Preload cover up
         <div className={`absolute z-50 flex justify-center items-center h-screen w-screen bg-black ${this.state.loadComplete ? 'opacity-0 z-0' : 'opacity-100 '} ${this.state.hideOverlay ? 'w-0 h-0' : ''} transition-all duration-1000`}>
           <div className='flex flex-col items-center w-full'>
             <div className="text-center text-gray-700">Loading...</div>
             <LoadingBar></LoadingBar>
           </div>
         </div>
       } */}
       <Navbar className={`fixed top-0 z-30 ${this.state.loadComplete ? 'opacity-100' : 'opacity-1000'}`} clickHandler={this.handleClick}></Navbar>
       {
        <Routes >
          <Route path='/' element={<Home preLoad={!this.state.loadComplete} />} />
          <Route path="stills" element= {<Stills/>} />
          <Route path="about" element={<About/>}/>
          <Route path="Motion" element={<Motion/>}/>
          <Route path="Intro" element={<Intro/>}/>
          {/* <Route path="Shop" element={<div className='flex flex-auto h-full w-full'>
             <iframe className='h-full w-full' src='https://andrewrimanic.photoshelter.com/search/search?I_DSC=&I_DSC_AND=t&I_CITY=&I_STATE=&I_COUNTRY_ISO=&I_IS_RELEASED=&I_IS_PRELEASED=&I_ORIENTATION=&_CB_I_PR=t&_CB_I_PU=t&_CB_I_RF=t&_CB_I_RM=t&I_SORT=RANK&C_ID=&_ACT=search&F_REFINE=t'></iframe>
           </div>}/> */}
        </Routes>

       }
       <Footer></Footer>
     </div>
   )
 }
}





