import './App.css';
import './components/Global'
import Pages from './components/Pages';
import ReactGA from "react-ga4";

function App() {

  const GAid = 'G-0QFJW9KFM7'
  ReactGA.initialize(GAid);
  
  return (
    <div className="App">
      <Pages />
    </div>
  );
}

export default App;
