import React, { Component } from 'react'
import menuIcon from '../assets/menu.png'
import { MdOutlineClose } from 'react-icons/md'

import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';
export class Navbar extends Component {

  constructor(props) {
    super(props)

    this.state = {
      menuState: false
    }
  }

  render() {
    const topBarItems = []

    for (const [k, value] of Object.entries(window.menuItems)) {
      topBarItems.push(<li
        key={value}
        className='flex h-full px-6 inset-0 items-center justify-center border-white/50'
      >
        {k === "Shop" ?
          <a href="https://andrewrimanic.photoshelter.com/index" target="_blank">{k}</a>
          :
          <Link to={k === "Home" ? '/' : `/${k}`}>{k}</Link>
        }
      </li>
      )
    }

    const sideBarItems = []
    for (const [k, value] of Object.entries(window.menuItems)) {
      sideBarItems.push(<li className='clear-right h-12 '
        key={k}
      >
        {/* divider */}
        <div className='h-[1px] w-full bg-gradient-to-r from-transparent via-white/70'></div>

        {/* content */}
        <div className='flex h-full mx-4 inset-0 items-center justify-center'>

          {
            k === "Shop" ?
              <a className='' href="https://andrewrimanic.photoshelter.com/index" target="_blank">{k}</a>
              :
              <Link className='' to={k === "Home" ? '/' : `/${k}`}
                onClick={() => {
                  this.setState((prevState) => ({ menuState: !prevState.menuState }));
                  this.props.clickHandler(value)
                }} >{k}
              </Link>
          }

        </div>


      </li>)
    }

    let navBarItems = <div></div>
    if (isMobile) {
      navBarItems = <div className='flex flex-1 mr-2'>
        <div className='flex flex-1 justify-end items-center'>
          <img
            src={menuIcon}
            alt='menu'
            className='object-contain w-10 h-10 bg-transparent'
            onClick={() => this.setState((prevState) => ({
              menuState: !prevState.menuState
            }))}>
          </img>
        </div>

        <div
          // Sidebar fade in
          className={`${this.state.menuState ? 'opacity-100 w-1/4 right-0' : 'opacity-0 w-0 right-0'} top-0 flex p-1 bg-zinc-800 absolute sidebar transition-all duration-500 z-50`} >
          <ul className='list-none sm:flex justify-center items-center flex-1'>
            {/* <ul className='list-none sm:flex justify-center items-center flex-1 divide-y divide-white/30'> */}
            <li className='justify-items-end'>
              <MdOutlineClose
                className='object-contain w-10 h-10 bg-transparent float-right'
                onClick={() => this.setState((prevState) => ({
                  menuState: !prevState.menuState
                }))}>

              </MdOutlineClose>
            </li>
            {sideBarItems}
          </ul>
        </div>
      </div>
    }

    else {
      navBarItems = <div className='flex flex-1'>
        <ul className='list-none sm:flex justify-end hidden items-center flex-1 divide-x'>
          {topBarItems}
        </ul>
      </div>
    }



    return (
      <nav className={`${this.props.className} w-full flex justify-between items-center text-white bg-zinc-800 h-12 select-none opacity-75`}>
        <p className='pl-2 lg:text-3xl lg:pl-4 '>Andrew Rimanic</p>
        {navBarItems}
      </nav >
    )
  }
}

export default Navbar