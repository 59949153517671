import React, { Component } from 'react'
import iglogo from '../assets/ig-logo-col.png'
import logo from '../assets/logo-wide-512.png'
export default class Footer extends Component {
  render() {
    return (
      <div className='h-auto w-full overflow-auto bg-black/95'>
        <div className=' h-auto w-full align-middle flex flex-row'>

          {/* Dummy image for centering purposes */}
          <img className='h-8 w-auto mx-2 opacity-0'
            key={'dummy'}
            src={iglogo}
          >
          </img>

          {/* Logo */}
          <img className=' object-cover h-16 mx-auto bg-transparent my-1'
            key={'logo'}
            src={logo}
          >
          </img>

          {/* Instagram */}
          <a className='cursor-pointer mx-2 my-auto' href='https://www.instagram.com/andrew.rimanic/' target={'_blank'}>
            <img className='h-8 w-auto'
              key={'instagram'}
              src={iglogo}
            >
            </img>
          </a>

        </div>
      </div>
    )
  }
}
