import React, { Component } from 'react'
import exifr from 'exifr'
import FilterBar from './FilterBar'
import { SlArrowLeft, SlArrowRight } from 'react-icons/sl'
import { MdOutlineFilterList, MdOutlineClose} from 'react-icons/md'
// import { getSignedUrl, } from "@aws-sdk/s3-request-presigner";
// import { S3Client, GetObjectCommand, ListObjectsV2Command } from "@aws-sdk/client-s3";


export default class Stills extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selected: -1,
      filters: '',
      showFilters: false,
      loadComplete: false,
      loadCount: 0
    }

    this.loadTime = 1000

    // Each set holds the indices of the images that match the filter key
    this.filters = {//'Canada': new Set(),
      //'USA': new Set(),
      //'Peru': new Set(),
      'Wildlife': new Set(),
      'Landscape': new Set(),
      'Astro': new Set()
    }


    // const creds = {
    //   accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    //   secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    // };      
    // this.client = new S3Client({ region: 'us-west-2', credentials: creds })

    // const paths = this.getBucketObjs("images/Thumbnails/").then(paths => paths.map(path => getSignedUrl(this.client, new GetObjectCommand({ Bucket: "andrewrimanic.com", Key: path }), { expiresIn: 3600 })))
    // console.log(paths)
    // Grab all image paths
    this.thumbnail_paths = this.importAll(require.context('../images/Thumbnails', false, /^\.\/.*\.jpg$/));

    // Generate all metadata 
    this.thumbnail_paths.forEach(function (path, id) {

      // Get metadata
      exifr.parse(path, true)
        .then(output => {
          // Get all keywords to lower case
          const kw = output.Keywords
          const lowerKw = kw.map(word => word.toLowerCase());

          // Search through filters to see if this images keywords match
          for (const [key, value] of Object.entries(this.filters)) {
            if (lowerKw.includes(key.toLowerCase())) {
              value.add(id)
            }
          }
        }
        )

    }, this)

    // Get all high res paths
    this.paths = this.importAll(require.context('../images/highres', false, /^\.\/.*\.jpg$/));

    // Binding
    this.leftArrowClick = this.leftArrowClick.bind(this)
    this.rightArrowClick = this.rightArrowClick.bind(this)
    this.handleLoad = this.handleLoad.bind(this)
  }

  componentDidMount () {
    document.body.scrollTo(0, 0)
  }

  //  async getBucketObjs(prefix = "") {
  //     const input = { // ListObjectsV2Request
  //       Bucket: "andrewrimanic.com", // required
  //       Prefix: prefix
  //     };
  //     const command = new ListObjectsV2Command(input);
  //     const response = await this.client.send(command);
  //     return response.Contents.map((obj) => (obj.Key) )
  //   }

  // Helper to get all image paths in a folder
  importAll(r) {
    let images = [];
    r.keys().map(item => { images.push(r(item)) });
    return images;
  }

  // Handle image click event
  handleClick = (newSelection) => {
    this.setState({
      selected: newSelection,
      showFilters: false
    }, () => console.log(newSelection))
  }

  // Handler for filter bar clicks
  filterClick = (newFilter) => {
    this.setState((prev) => {
      var newFilters = prev.filters

      if (prev.filters == newFilter) {
        // Remove filter
        // prev.filters.splice(prev.filters.indexOf(newFilter), 1)
        newFilters = ''
      } else {
        // Add filter
        // prev.filters.push(newFilter)
        newFilters = newFilter
      }

      return {
        filters: newFilters,
        loadComplete: false
      }
    }
    )

    this.loadInterval = setInterval(() => {
      this.setState({ loadComplete: true });
      clearInterval(this.loadInterval);
    }, this.loadTime);

  }

  // Sets thumbnail path property to paths of all images that meet the current filter requirement  
  filterThumbnails() {
    // No filters chosen
    if (this.state.filters == '') {
      this.filter_paths = this.thumbnail_paths.map(
        (name, index) => ({
          path: name,
          id: index
        })
      )
    } else {
      this.filter_paths = []
      for (const index of this.filters[this.state.filters]) {
        this.filter_paths.push({
          path: this.thumbnail_paths[index],
          id: index
        })
      }
    }
  }

  // Click handler for left arrow button
  leftArrowClick(e) {
    this.setState((prev) => {
      return {
        selected: this.prevInFilter(prev.selected)
      }
    }, () => console.log(this.state.selected))
    e.stopPropagation()
  }

  // Click handler for right arrow button
  rightArrowClick(e) {
    this.setState((prev) => {
      return {
        selected: this.nextInFilter(prev.selected)
      }
    }, () => console.log(this.state.selected))
    e.stopPropagation()
  }

  // Return the path index of the next image that fits the current filter
  nextInFilter(n) {
    // find index of current image in filtered paths
    var idx = this.filter_paths.findIndex((element) => element.id === n)
    // get the next index that meets the filter 
    return this.filter_paths[this.wrapNumber(idx + 1, 0, this.filter_paths.length - 1)].id
  }

  // Return the path index of the previous image that fits the current filter
  prevInFilter(n) {
    // find index of current image in filtered paths
    var idx = this.filter_paths.findIndex((element) => element.id === n)
    // get the next index that meets the filter 
    return this.filter_paths[this.wrapNumber(idx - 1, 0, this.filter_paths.length - 1)].id
  }

  // Helper for arrow click handlers. If n > max return min. If n < min return max, Else return n
  wrapNumber(n, min, max) {
    var next = n

    if (next < min) {
      next = max // underflow
    } else if (next > max) {
      next = min // overflow
    }

    return next
  }

  // Called whenever a thumbnail image finishes loading. Sets loadComplete to true when all images have loaded
  handleLoad() {
    this.setState((prev) => {
      var newCount = prev.loadCount + 1
      var loadComplete = false
      if (newCount === this.thumbnail_paths.length) {
        loadComplete = true
      }

      return {
        loadCount: newCount,
        loadComplete: loadComplete
      }
    })
  }

  render() {
    return (
      <div className='flex flex-col pt-12 w-full h-auto min-h-screen bg-zinc-900 select-none'>

        {/* Show Filters if selected */}
        {this.state.showFilters &&
          <FilterBar filters={this.filters} click={this.filterClick} active={this.state.filters}></FilterBar>
        }

        {/* Columns */}
        <div className='m-1 gap-1 sm:columns-1 md:columns-2 lg:columns-3 xl:columns-4 2xl:columns-5'>
          {/* All images that fit the current filter */}
          {this.filterThumbnails()}
          {
            this.filter_paths.map((obj) =>
              <img className={`object-contain mb-1 cursor-pointer max-h-[90vh] m-auto ${this.state.loadComplete ? 'opacity-100 transition-all duration-500' : 'opacity-0'}`}
                key={obj.id}
                src={obj.path}
                onClick={() => this.handleClick(obj.id)}
                onLoad={() => this.handleLoad()}
              >
              </img>
            )
          }

          {/* Pop up when clicking on image */}
          {this.state.selected !== -1 &&
            <div
              className={`fixed inset-0 m-auto flex flex-auto h-80% object-contain place-content-center bg-black/80`}
              onClick={() => this.handleClick(-1)}>


              {/* Pop up image */}
              <img className={`fixed object-contain cursor-pointer max-h-[90vh] inset-0 m-auto `}
                key={this.state.selected}
                src={this.paths[this.state.selected]}
                onContextMenu={(e) => e.preventDefault()}
                draggable="false"
              ></img>

              {/* Preload images before and after the selected one */}
              <img className={`fixed w-0 h-0 inset-0 m-auto -z-50 opacity-0`}
                key={this.state.selected + 1}
                src={this.paths[this.nextInFilter(this.state.selected)]}
                onContextMenu={(e) => e.preventDefault()}
                draggable="false"
              ></img>
              <img className={`fixed w-0 h-0 inset-0 m-auto -z-50 opacity-0`}
                key={this.state.selected - 1}
                src={this.paths[this.prevInFilter(this.state.selected)]}
                onContextMenu={(e) => e.preventDefault()}
                draggable="false"
              ></img>


              {/* Button bar appearing below the image */}
              <div className={`h-full w-full text-white`}>
                <div className='fixed h-8 w-1/2 bottom-2  grid grid-cols-2 place-items-center inset-x-0 mx-auto'>

                  <SlArrowLeft onClick={this.leftArrowClick} className='cursor-pointer bottom-0 h-full w-4/5 p-2 border rounded-md border-stone-500/50 hover:bg-white/30 active:bg-white/50'></SlArrowLeft>
                  <SlArrowRight onClick={this.rightArrowClick} className='cursor-pointer bottom-0 h-full w-4/5 p-2 border rounded-md border-stone-500/50 hover:bg-white/30 active:bg-white/50'></SlArrowRight>
                </div>

              </div>
            </div>
          }

        </div>

        {/* Sticky Filter button */}
        {(this.state.selected === -1 && this.state.loadComplete) &&
          <div className={`pointer-events-none sticky w-full h-10 bottom-4 float-right z-50 ${this.state.showFilters ? 'brightness-100' : 'brightness-75'}  hover:brightness-100 cursor-pointer`}>
            {this.state.showFilters ?
              <MdOutlineClose
                className='pointer-events-auto object-contain w-10 h-10 bg-transparent text-white border-white border-2 rounded-full float-right mr-4'
                onClick={() => this.setState((prevState) => ({
                  showFilters: !prevState.showFilters
                }))}>
              </MdOutlineClose>
              :
              <MdOutlineFilterList
                className='pointer-events-auto object-contain w-10 h-10 bg-transparent text-white border-white border-2 rounded-full float-right mr-4'
                onClick={() => this.setState((prevState) => ({
                  showFilters: !prevState.showFilters
                }))}>
              </MdOutlineFilterList>
            }
          </div>
        }
      </div>
    )
  }
}
