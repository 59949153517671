import React, { Component } from 'react'

export default class Motion extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loadComplete: false
    }
  }

  componentDidMount(){
    document.body.scrollTo(0, 0)
    this.loadInterval = setInterval(() => {
      this.setState({ loadComplete: true });
      clearInterval(this.loadInterval);
    }, 300);
  }

  render() {
    return (
      <div className={`flex flex-auto h-full w-full bg-neutral-900 ${this.state.loadComplete ? 'brightness-100 transition-all duration-1000' : 'brightness-0'}`}>
        <div className='m-auto w-5/6 h-[46.875vw]'>
          <iframe className='m-auto object-contain'
          width="100%" 
          height="100%" 
          src="https://www.youtube.com/embed/e0r9L2gEsAQ" 
          title="YouTube video player" 
          frameBorder="0" 
          allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
          ></iframe>
        </div>
      </div>
    )
  }
}
