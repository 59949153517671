import React, { Component } from 'react'
import { scrollTo } from "../utils/scrollTo";
import aboutbg from '../assets/DJI_0020-Edit-2.jpg'
import face from '../assets/DSC01013.jpg'
import pano from '../assets/DSC09877-Pano.jpg'
import fullbody from '../assets/DSC00971.jpg'
import rapp from '../assets/DSC09789.jpg'
import bcmagcover from '../assets/bcmagazine-cover-fall-22.jpg'
import bcmagpage from '../assets/bcmagazine-page.jpg'

export default class About extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loadCount: 0,
      loadComplete: false,
      showBanner: false,
      showPub: false
    }

    this.myRef = React.createRef()
    this.handleClick = this.handleClick.bind(this)
    this.handleLoad = this.handleLoad.bind(this)
  }

  componentDidMount() {
    document.body.scrollTo(0, 0)
    // this.loadInterval = setInterval(() => {
    //   this.setState({ loadComplete: true });
    //   clearInterval(this.loadInterval);
    // }, 500);

    // this.bannerInterval = setInterval(() => {
    //   // this.setState({ showBanner: true });
    //   //  this.myRef.scrollIntoView()
    //   scrollTo({id: 'scroll', duration:2000})
    //   clearInterval(this.bannerInterval);
    // }, 2000);

  }

  handleClick(e, val) {
    this.setState({
      showPub: val
    })
    e.stopPropagation()
  }

  handleLoad() {
    this.setState((prev) => {
      var newCount = prev.loadCount + 1
      var loadComplete = false
      if (newCount === 5) {
        loadComplete = true
        this.bannerInterval = setInterval(() => {
          scrollTo({ id: 'scroll', duration: 2000 })
          clearInterval(this.bannerInterval);
        }, 2000);
      }

      return {
        loadCount: newCount,
        loadComplete: loadComplete
      }
    })
  }


  render() {
    return (
      <div className={`w-full  bg-zinc-200 relative ${this.state.loadComplete ? 'overflow-y-auto h-auto' : 'overflow-y-hidden h-full'}`}>
        <img className={`h-screen w-full object-cover `}
          key={0}
          src={aboutbg}
        >

        </img>
        {/* Gradients */}
        <div className='h-48 w-full bg-gradient-to-t from-zinc-800 to-black'></div>
        <div className=' h-96 w-full bg-gradient-to-t from-zinc-400 to-zinc-800'></div>

        {/* Content */}
        <div className=' w-full bg-gradient-to-t from-zinc-200 to-zinc-400 justify-items-start text-sm md:text-lg lg:tracking-wider !leading-6 md:!leading-8 text-left font-light md:indent-12'>
          <div id='scroll' className='overflow-y-auto h-auto w-screen py-2 pt-4'>

            <img className={`w-full md:w-1/2 p-2 object-cover mx-auto inset-x-0 md:float-right `}
              key={1}
              src={face}
              onLoad={() => this.handleLoad()}
            >
            </img>
            <p className='px-4'>Andrew Rimanic is a Canadian mountaineer and award winning photographer known for his expertise in shooting landscapes, wildlife, action sports, and adventure travel destinations. Andrew mastered his craft through years of experience exploring the Pacific North West through the lens of his camera.</p>
          </div>

          <div className='overflow-y-auto h-auto w-screen py-2'>
            <img className={`w-full md:w-1/2 p-2 object-cover mx-auto inset-x-0 float-right  `}
              key={2}
              src={rapp}
              onLoad={() => this.handleLoad()}

            >
            </img>
            <p className='px-4'>During his teenage years, Andrew began hiking through the wilderness surrounding his hometown of Vancouver where he would develop his love for the outdoors. For many years he would seek out the best views from the most picturesque locations. In 2019, Andrew decided it was time to start sharing his experiences with others who could not be there, so he borrowed his father's camera, a Canon 7D, and ventured out in to the mountains. His newfound passion for photography drove him to look for new places to photograph, forcing him to travel farther and climb higher than before. It was at this point that Andrew ventured into overnight backpacking trips and started rock climbing to open up new possibilities for himself. </p>
          </div>

          <div>
            <img className={`w-full p-2 object-cover mx-auto inset-x-0 `}
              key={3}
              src={pano}
              onLoad={() => this.handleLoad()}
            >
            </img>
            <p className='px-4'>As an adventure athlete, Andrew's pursuits have taken him everywhere from the summits of glacier covered mountains to the depths of a giant canyon hidden in the jungles of Latin America. His skills in surviving in hostile environments and travelling through rugged terrain combined with his experience planning creative landscape compositions allow him to reach locations and capture scenes that others cannot, setting him apart from the average photographer.</p>
            <img className={`w-auto p-2 object-cover mx-auto inset-x-0 `}
              key={4}
              src={fullbody}
              onLoad={() => this.handleLoad()}
            >
            </img>


            <p className='px-4'>In 2022 Andrew received his first photography award, and was subsequently published in British Columbia Magazine as the winner of the magazine's annual photo competition. His iconic photograph features a barren glaciated landscape dotted with a trio of skiers ascending a prominent snow capped mountain in the backcountry of Pemberton, British Columbia. </p>

            <div className='w-[90%] mx-auto inset-x-0 h-[1px] bg-black my-6'></div>

            <h1 className='text-4xl mx-auto inset-x-0 w-full text-center'>Publications</h1>
            <div className='h-72'>
              <img className={`h-52 hover:h-72 w-auto p-2 object-cover mx-auto inset-x-0 align-middle cursor-pointer`}
                key={5}
                src={bcmagcover}
                onClick={(e) => this.handleClick(e, true)}
                onLoad={() => this.handleLoad()}
              >
              </img>
            </div>
          </div>

        </div>
        {/* Pop up image */}
        {this.state.showPub &&
          // <div className='fixed h-96 w-full top-0 bg-black'></div>
          <div
            className={`fixed inset-0 m-auto flex flex-auto h-80% object-contain place-content-center ${this.state.showPub ? '' : ' h-0 w-0'} bg-black/80`}
            onClick={(e) => this.handleClick(e, false)}>

            <img className={`fixed bottom-6 object-contain cursor-pointer max-h-[90vh] `}
              key={6}
              src={bcmagpage}
              onContextMenu={(e) => e.preventDefault()}
              draggable="false"
              onClick={(e) => this.handleClick(e, false)}
            ></img>
          </div>

        }

      </div>
    )
  }
}
