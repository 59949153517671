import React, { Component } from 'react'

export default class LoadingBar extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
       toggle: false
    }
  }

  componentDidMount() {
    this.loadInterval = setInterval(() => {
      this.setState((prev) => { return {toggle: !prev.toggle }});
    }, 600);

  }


  render() {
    return (

      <div className={`flex h-12 w-2/3 md:w-1/4 text-2xl absolute top-1/3 inset-0 m-auto transition-all duration-1000 rounded-xl bg-black/70 !filter-none ${this.state.toggle ? 'text-zinc-400': 'text-zinc-500'}`}>
        <div className='m-auto inset-0'>Loading... </div>
      </div>

      // <div className="h-5 w-1/3 rounded-lg overflow-hidden bg-black absolute inset-0 m-auto">
      //   <div className="h-full w-0 transition-all duration-100 bg-slate-600" style={{ width: `${progress}%` }} />
      // </div>
  );
  }
}
