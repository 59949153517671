import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function HomePanelVideo(props) {

  const navigate = useNavigate()
  return (
    <div className={`${props.className} grow w-1/6 hover:w-3/4 h-full relative transition-home duration-500 z-2 tracking-[0.25em]  border-white/50 group-hover:border-black` }
      onClick={() => navigate(props.name)}>

      <video className={`${props.imgClassName} flex flex-1 transition-size duration-500 object-cover w-full h-full `}
        poster={props.img}
        onMouseOver={event => event.target.play()}
        onMouseOut={event => event.target.pause()}
        src={props.video} 
        onCanPlay={() => props.onLoad()}
        loop
        preload='auto'
        muted>
      </video>

      <p className='text-[5vw] bottom-[12.5%] opacity-75 group-hover:opacity-90 pointer-events-none transition-size duration-500 text-white absolute left-0 right-0'> 
      {props.name}
       </p>

      </div>
  )
}
