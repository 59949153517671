import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function HomePanel(props) {

  const navigate = useNavigate()
  return (
    <div className={`${props.className} grow w-1/6 hover:w-3/4 h-full relative transition-home duration-500 z-2 tracking-[0.25em] border-white/50 group-hover:border-black` }
      onClick={() => navigate(props.name)}>

      <img className={`${props.imgClassName} flex flex-initial transition-size duration-500`}
        src={props.img}
        onContextMenu={(e)=> e.preventDefault()}
        draggable="false"
        onLoad={() => props.onLoad()}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover'
        }}>

      </img>
      <p className='text-[5vw] bottom-[12.5%] opacity-75 group-hover:opacity-90 pointer-events-none transition-size duration-500 text-white absolute left-0 right-0'> 
      {props.name}
       </p>

      </div>
  )
}
