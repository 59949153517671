import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function MobileHomePanel(props) {
  const navigate = useNavigate()
  return (
    <div
      className={`flex flex-1 relative h-1/3 ${props.className}`}
      onClick={() => navigate(props.name)}>
      
      <img className='h-full' 
      src={props.img} 
      onContextMenu={(e)=> e.preventDefault()}
      onLoad={() => props.onLoad()}
      draggable="false"
      style={{
        width: '100%',
        height: '100%',
        objectFit: 'cover'
      }}>
        
      </img>
      <p className='text-[5vw] bottom-[12.5%] opacity-90 tracking-wider text-white absolute left-0 right-0'> {props.name} </p>
    </div>
  )
}
