
// import React, { Component } from 'react'
import React, { Component } from 'react'
import { isMobile } from 'react-device-detect';
import HomePanel from './HomePanel';
import MobileHomePanel from './MobileHomePanel'
import HomePanelVideo from './HomePanelVideo';
import './Global'
import LoadingBar from './LoadingBar';
import ProgressiveImage from './ProgressiveImage';

// Assets
import motionbg from '../images/highres/IMG_7698.jpg'
import aboutbg from '../images/highres/IMG_0652.jpg'
import stillsbg from '../images/highres/IMG_0264.jpg'
// import video from '../assets/Website.mp4'
import mobileSplashLow from '../assets/mobile-splash-low.jpg'
import mobileSplash from '../assets/mobile-splash.jpg'
import desktopSplashLow from '../assets/desktop-splash-low.jpg'
import desktopSplash from '../assets/desktop-splash.jpg'


// AWS Imports
import { getSignedUrl, } from "@aws-sdk/s3-request-presigner";
import { S3Client, GetObjectCommand } from "@aws-sdk/client-s3";

let media = {}
media[window.homePanels.Stills] = [stillsbg,]
media[window.homePanels.About] = [aboutbg,]
media[window.homePanels.Motion] = [motionbg, "dummy source. use aws url"]

export default class Home extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showSplash: window.sessionStorage.getItem('firstView'), // Init to false, on first opening, true on others
      gray: true,
      loadComplete: false,
      loadCount: 0
    }
    console.log(desktopSplash)
    this.handleLoad = this.handleLoad.bind(this)
    this.getS3('andrewrimanic.com', 'Website.mp4')

  }

  componentDidMount() {
    window.sessionStorage.setItem('firstView', false)
    document.body.scrollTo(0, 0)
    this.grayInterval = setInterval(() => {
      this.setState({ gray: false });
      clearInterval(this.grayInterval);
    }, 500);
  }

  handleLoad() {
    this.setState((prev) => {
      var newCount = prev.loadCount + 1
      var loadComplete = false
      if (newCount === 3) {
        loadComplete = true

        this.loadInterval = setInterval(() => {
          this.setState({ showSplash: false });
          clearInterval(this.loadInterval);
        }, 3500);
      }

      return {
        loadCount: newCount,
        loadComplete: loadComplete
      }
    })
  }

  async getS3(Bucket, Key) {
    const creds = {
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    };

    const client = new S3Client({ region: 'us-west-2', credentials: creds }) // Pass in opts to S3 if necessary

    const getObjectCommand = new GetObjectCommand({ Bucket, Key })

    this.video_url = await getSignedUrl(client, getObjectCommand, { expiresIn: 3600 })

  }

  render() {
    if (isMobile) {
      // Mobile view
      console.log(this.state.loadComplete)

      // Create all of the mobile panels
      const items = []
      for (const [k, value] of Object.entries(window.homePanels)) {
        items.push(<MobileHomePanel className={`${this.state.loadComplete ? 'opacity-100 transition-all duration-1000' : 'opacity-0'}`}
          key={value}
          name={k}
          img={media[value][0]}
          onLoad={this.handleLoad}
        >

        </MobileHomePanel>)
      }

      return (
        <div className='h-full '>
          {this.state.showSplash === 'true' &&
            // <div className={`fixed h-full w-full bg-bottom bg-no-repeat bg-cover bg-[url('./assets/mobile-splash.jpg')] z-50 transiton-all duration-[2000ms] ${this.state.gray ? 'grayscale-[75%]' : 'grayscale-[0%]'}`}>
            <div className={`fixed h-full w-full z-50 transiton-all duration-[2000ms] ${this.state.gray ? 'grayscale-[75%]' : 'grayscale-[0%]'}`}>
              <ProgressiveImage className='h-full w-full'
                imgClassName='h-full w-full object-cover'
                src={mobileSplash}
                placeholder={mobileSplashLow}></ProgressiveImage>
              <LoadingBar></LoadingBar>
            </div>
          }

          <div style={{ backgroundImage: this.state.loadComplete ? '' : `url(${mobileSplashLow})` }} // Do this way until tailwind supports dynamic url
            className={`flex flex-col h-full pt-12 bg-cover bg-center transition-all duration-1000 bg-black`}>
            {!this.state.gray &&
              items}
          </div>
        </div>
      )

    } else {
      // Browser 

      // Create browser home panels
      const items = []
      for (const [k, value] of Object.entries(window.homePanels)) {
        if (media[value].length === 1) {
          items.push(<HomePanel
            key={value}
            name={k}
            img={media[value]}
            imgClassName={`group-hover:opacity-100 group-hover:brightness-50 hover:!brightness-100 opacity-0  `}
            onLoad={this.handleLoad}
            className=''
          >
          </HomePanel>)
        } else {
          items.push(<HomePanelVideo
            key={value}
            name={k}
            img={media[value][0]}
            video={this.video_url}
            imgClassName={`group-hover:opacity-100 group-hover:brightness-50 hover:!brightness-100 opacity-0  `}
            onLoad={this.handleLoad}
            className=''
          >
          </HomePanelVideo>)

        }
      }

      return (
      <div className='w-full h-full'>
        {this.state.showSplash === 'true' &&
          // <div className={`fixed h-full w-full bg-center bg-cover bg-[url('./images/highres/IMG_0263.jpg')] z-50 transiton-all duration-[1500ms]  ${this.state.gray ? 'grayscale-[75%]' : 'grayscale-[0%]'}`}>
          <div className={`fixed h-full w-full z-50 transiton-all duration-[2000ms] ${this.state.gray ? 'grayscale-[75%]' : 'grayscale-[0%]'}`}>
            <ProgressiveImage className='h-full w-full'
              imgClassName='h-full w-full object-cover'
              src={desktopSplash}
              placeholder={desktopSplashLow}></ProgressiveImage>
            <LoadingBar></LoadingBar>
          </div>
        }
        {/* <div className={`flex flex-row w-full bg-center bg-cover bg-[url('./images/highres/IMG_0263.jpg')] divide-x group h-full cursor-pointer hover:divide-x-2`} */}
        <div style={{ backgroundImage: `url(${desktopSplash})` }} // Do this way until tailwind supports dynamic url
          className={`flex flex-row w-full bg-center bg-cover divide-x group h-full cursor-pointer hover:divide-x-2`}
        >
          {/* <div className='h-1/2 w-full bg-blue-700/30 absolute bottom-0 z-50 peer'>
            
          </div> */}
          {!this.state.gray &&
            items}
        </div>
      </div>
      )
    }
  }
}

